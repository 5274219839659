const colors = {
  brand: {
    primary: '#1D1D1D',
    secondary: '#F19A37',
    secondaryHover: '#F37321',
  },
  primary: '#C29B40',
  primaryBorder: '#EBEBEB',
  primaryHover: '#B9840A',
  secondary: '#1A2550',
  secondaryHover: '#171D38',
  background: '#ffffff',
  backgroundGrey: '#f2f2f2',
  backgroundLightGrey: '#fafafa',
  backgroundMerino: '#F5EFE0',
  videoBackground: '#000',
  imageBackgroundLight: '#ccc',
  imageBackgroundDark: '#171D38',
  backgroundWhite: '#ffffff',
  backgroundBlack: '#000',
  text: {
    dark: '#000',
    light: '#fff',
    gray: '#667080',
    semiTransparent: 'rgba(0, 23, 49, 0.56)',
    darkLiver: '#4e4e4e',
    darkBlue: '#171D38',
  },
  error: '#E11B22',
  warning: '#E47311',
  success: '#00A945',
  border: '#D7D7D7',
  borderSilver: '#d8d8d8',
  borderAlignment: '#ececec',
  transparentBackgroundButton: 'rgba(255, 255, 255, 0.1)',
  borderSteelGray: '#ADADAD',
  lineSeparator: '#d6d6d6',
  backgroundPinkSwan: '#B6B6B64D',
  mapBorderColor: 'rgba(74, 74, 74, 0.1)',
  mapSatelliteFillColor: '#E11B22',
  mapSatelliteStrokeColor: '#b2151a',
  semiTransparentOffWhite: 'rgba(255, 255, 255, 0.6)',
} as const;

export default colors;
