import {
  Flex,
  Box,
  Link,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Progress,
  PopoverArrow,
  PopoverHeader,
} from '@chakra-ui/react';

import style from './VendorRating.style';

import { Icon, Text } from '@/components';
import { ConsultantCustomerReview } from '@/components/organism/dashboard/HireConsultants/Consultants.type';
import useTranslation from '@/hooks/useTranslate';
import colors from '@/styles/themes/brand/colors';

interface VendorRatingProps {
  rate: string | number | undefined;
  reviews: ConsultantCustomerReview[] | undefined;
  reviewType: string;
}

const VendorRating = ({ rate, reviews, reviewType }: VendorRatingProps) => {
  const { t } = useTranslation();
  const ratingInfo = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };

  // Populate ratingInfo with counts of each responseRating, rounded as needed
  reviews?.forEach(review => {
    if (review.responseRating != null) {
      const responseRating = Math.ceil(review.responseRating);
      if (responseRating >= 1 && responseRating <= 5) {
        ratingInfo[responseRating as keyof typeof ratingInfo] += 1;
      }
    }
  });

  const totalRating = Object.values(ratingInfo).reduce(
    (acc, count) => acc + count,
    0
  );

  const roundingRate = (rate: number) => {
    if (rate % 1 === 0 || Math.round(rate * 10) % 10 === 0) {
      return `${Math.round(rate)}`;
    } else {
      return `${(Math.round(rate * 10) / 10).toFixed(1)}`;
    }
  };

  return (
    <Flex sx={style.outerContainer}>
      {reviewType === 'portal_rating_customer_rating' ? (
        <Icon name="profileCircle" sx={style.profileIcon} />
      ) : reviewType === 'portal_rating_teyaseer_rating' ? (
        <Icon name="teayseer" sx={style.profileIcon} />
      ) : null}

      <Flex sx={style.iconContainer}>
        <Flex sx={style.innerContainer}>
          <Flex sx={style.ratingContent}>
            <Icon name="starFill" sx={style.starIcon} />
            <Text marginLeft="5px">
              {typeof rate === 'number' && !isNaN(rate)
                ? t('portal_rating_info').replace(
                    '{{rating}}',
                    roundingRate(rate)
                  )
                : '- | 5'}
            </Text>
          </Flex>
          <Flex>
            {reviewType === 'portal_rating_customer_rating' ? (
              <Link>
                <Text sx={style.reviewText} textDecoration="underline">
                  {totalRating != 1
                    ? `${totalRating} ${t('portal_rating_customer_ratings')}`
                    : `${totalRating} ${t(reviewType)}`}
                </Text>
              </Link>
            ) : (
              <Text sx={style.reviewText}>{t(reviewType)}</Text>
            )}
          </Flex>
        </Flex>

        {/* Popover for detailed rating breakdown */}
        <Flex sx={style.popoverContainer}>
          <Popover trigger="hover" placement="top" offset={[0, 1]}>
            <PopoverTrigger>
              <Flex>
                <Icon name="infoNew" sx={style.infoIcon} />
              </Flex>
            </PopoverTrigger>
            <PopoverContent sx={style.popoverOneContentContainer}>
              <PopoverArrow
                css={{ '--popper-arrow-bg': '#1A2550' }}
                sx={{ ml: '-3px' }}
              />
              <Box>
                <Text sx={style.ratingInfoText}>
                  {/* {t('portal_vendorRating_Info')} */}
                  {reviewType === 'portal_rating_customer_rating'
                    ? t('portal_customerRating_Info')
                    : t('portal_TeyaseerRating_Info')}
                </Text>
              </Box>
            </PopoverContent>
          </Popover>

          {reviewType === 'portal_rating_customer_rating' && (
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <IconButton
                  aria-label="Rating details"
                  icon={<Icon name="blackArrowDown" sx={style.arrowIcon} />}
                  variant="ghost"
                  _hover={{ background: 'none' }}
                />
              </PopoverTrigger>
              <PopoverContent sx={style.popoverTwoContentContainer}>
                <PopoverArrow />
                <Flex sx={style.popoverHeaderContainer}>
                  <Icon name="rating" sx={style.popoverHeaderIcon} />
                  <PopoverHeader>
                    <Text sx={style.popoverHeaderOne}>
                      {t('portal_total_rating').replace(
                        '{{total}}',
                        `${totalRating}`
                      )}
                    </Text>
                    <Text sx={style.popoverHeaderTwo}>
                      {t('portal_rating_popover_sub_header')}
                    </Text>
                  </PopoverHeader>
                </Flex>

                {Object.keys(ratingInfo)
                  .sort((a, b) => Number(b) - Number(a))
                  .map(key => {
                    const count =
                      ratingInfo[key as unknown as keyof typeof ratingInfo];
                    const percentage = totalRating
                      ? (count / totalRating) * 100
                      : 0;
                    return (
                      <Flex key={key} sx={style.popoverRatingContainer}>
                        <Flex sx={style.rateBreakdown}>
                          <Text>{key}</Text>
                          <Icon
                            name="starFill"
                            width="16px"
                            height="16px"
                            color={colors.primary}
                            paddingTop="3px"
                          />
                        </Flex>
                        <Box sx={style.rateProgressContainer}>
                          <Progress
                            flex="2"
                            value={percentage}
                            colorScheme="green"
                            sx={style.rateProgress}
                          />
                        </Box>
                        {/* <Text flex="1">{count}</Text> */}
                        <Text flex="1" textAlign="right">
                          {Math.round(percentage)}%{' '}
                        </Text>
                      </Flex>
                    );
                  })}
              </PopoverContent>
            </Popover>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default VendorRating;
