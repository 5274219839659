import { SVGProps } from 'react';

interface LogoProps extends SVGProps<SVGSVGElement> {
  isScrolled?: boolean;
}

export default function Logo({ isScrolled, ...props }: LogoProps) {
  return isScrolled ? (
    <svg
      width="150"
      height="42"
      viewBox="0 0 150 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 25.6128V28.6301H4.74679V41.7092H8.22342V28.6301H12.9417V25.6128H0Z"
        fill="#231F20"
      />
      <path
        d="M20.0171 25.6128V41.7092H31.5014V38.7403H23.4734V35H31.3304V32.0554H23.4734V28.5574H31.5014V25.6128H20.0171Z"
        fill="#231F20"
      />
      <path
        d="M49.3039 25.6128L45.3144 32.0796L41.2759 25.6128H37.3352L43.5883 35.1212V41.7092H47.0405V35.1212L53.2447 25.6128H49.3039Z"
        fill="#231F20"
      />
      <path
        d="M80.0929 36.8338C81.2368 38.0173 83.0118 39.0069 85.2508 39.0069C87.1479 39.0069 88.072 38.1142 88.072 37.1973C88.072 35.9896 86.6594 35.5816 84.7867 35.1454C82.1365 34.5436 78.7291 33.8165 78.7291 30.2216C78.7291 27.5436 81.0618 25.3705 84.8845 25.3705C87.4614 25.3705 89.6027 26.1419 91.2067 27.6163L89.2852 30.1246C87.9703 28.9169 86.2197 28.3635 84.6402 28.3635C83.0607 28.3635 82.2546 29.0381 82.2546 30.0035C82.2546 31.09 83.6184 31.4253 85.491 31.8615C88.1657 32.4634 91.5731 33.2631 91.5731 36.8338C91.5731 39.7784 89.4562 42 85.0799 42C81.9655 42 79.7265 40.9619 78.1958 39.4432L80.0929 36.8378V36.8338Z"
        fill="#231F20"
      />
      <path
        d="M98.7747 25.6128V41.7092H110.255V38.7403H102.227V35H110.084V32.0554H102.227V28.5574H110.255V25.6128H98.7747Z"
        fill="#231F20"
      />
      <path
        d="M117.815 25.6128V41.7092H129.299V38.7403H121.271V35H129.128V32.0554H121.271V28.5574H129.299V25.6128H117.815Z"
        fill="#231F20"
      />
      <path
        d="M62.5346 25.6128L56.2571 41.7092H60.0513L64.7003 28.5816L69.3453 41.7092H73.1151L66.862 25.6128H62.5346Z"
        fill="#231F20"
      />
      <path
        d="M146.348 35.5816C148.099 35.1737 149.898 33.6509 149.898 30.8032C149.898 27.8102 147.83 25.6128 144.451 25.6128H136.863V28.5534H143.942C145.33 28.5534 146.377 29.4259 146.377 30.775C146.377 32.1241 145.33 32.9965 143.942 32.9965H136.863V41.7092H140.319V35.9411H142.847L146.035 41.7092H150L146.352 35.5776L146.348 35.5816Z"
        fill="#231F20"
      />
      <path
        d="M146.177 6.82631V13.6042C146.177 14.6301 145.697 14.9654 144.622 14.9654H115.437C113.793 14.9654 112.742 14.3918 112.742 13.3658V6.82631H109.091V12.4328C109.091 14.2706 109.062 14.9613 107.564 14.9613H102.321C101.242 14.9613 100.765 14.6261 100.765 13.6001V0H97.1138V14.2706C97.1138 16.4882 98.1926 17.5141 100.912 17.5141H108.46C110.495 17.5141 111.692 17.2031 112.29 16.2014C112.97 17.1991 116.846 17.4657 118.8 17.5061C118.849 17.5061 118.898 17.5141 118.951 17.5141H146.051C148.746 17.5141 149.821 16.4882 149.821 14.2706V6.82631H146.169H146.177Z"
        fill="#231F20"
      />
      <path d="M141.976 0.0203514H145.554V3.57488H141.976" fill="#231F20" />
      <path
        d="M149.76 0.0203514H146.181V3.57084H149.76V0.0203514Z"
        fill="#231F20"
      />
      <path
        d="M106.184 18.5038H102.605V22.0542H106.184V18.5038Z"
        fill="#231F20"
      />
      <path
        d="M110.385 18.5038H106.807V22.0542H110.385V18.5038Z"
        fill="#231F20"
      />
      <path
        d="M12.3881 22.0098L18.0549 5.90536L22.2277 17.5141H70.925C72.8994 17.5141 73.9782 17.1546 74.5767 16.1529C75.1466 17.0133 76.1929 17.5141 77.7195 17.5141H79.124C81.0373 17.5141 82.1772 17.1546 82.7431 16.1771C83.313 17.0133 84.3593 17.5141 85.8859 17.5141H87.2904C90.193 17.5141 91.3288 16.6093 91.3288 14.2706V6.82631H87.6771V12.4328C87.6771 14.6059 87.498 14.9613 85.434 14.9613C84.0865 14.9613 83.1583 14.4362 83.1583 13.3376V8.84997H79.5066V13.0023C79.5066 14.7432 79.2054 14.9573 77.3205 14.9573C75.7654 14.9573 74.9878 14.4322 74.9878 13.3578V8.84593H71.3362V12.9983C71.3362 14.4766 71.157 14.9533 69.8095 14.9533H24.6092L19.9927 3.1506H16.1008L9.96585 19.3722H0.0773926V22.0017H12.38L12.3881 22.0098Z"
        fill="#231F20"
      />
      <path
        d="M19.8338 13.6405H16.2554V17.191H19.8338V13.6405Z"
        fill="#231F20"
      />
      <path
        d="M19.8338 18.4836H16.2554V22.034H19.8338V18.4836Z"
        fill="#231F20"
      />
      <path
        d="M66.3938 38.0133H62.8154V41.5638H66.3938V38.0133Z"
        fill="#231F20"
      />
    </svg>
  ) : (
    <svg
      width="150"
      height="42"
      viewBox="0 0 150 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 25.6104V28.6274H4.74284V41.7092H8.22364V28.6274H12.9421V25.6104H0Z"
        fill="white"
      />
      <path
        d="M20.0178 25.6104V41.7092H31.4983V38.7406H23.4701V34.9966H31.3274V32.0564H23.4701V28.5547H31.4983V25.6104H20.0178Z"
        fill="white"
      />
      <path
        d="M49.3012 25.6104L45.3115 32.0806L41.277 25.6104H37.3362L43.5853 35.1178V41.7092H47.0376V35.1178L53.242 25.6104H49.3012Z"
        fill="white"
      />
      <path
        d="M80.0949 36.8343C81.2389 38.0177 83.0139 39.0072 85.253 39.0072C87.1501 39.0072 88.0743 38.1146 88.0743 37.1978C88.0743 35.9902 86.6616 35.5823 84.7889 35.1461C82.1386 34.5443 78.7311 33.8173 78.7311 30.2227C78.7311 27.545 81.0638 25.3721 84.8866 25.3721C87.4636 25.3721 89.605 26.1435 91.209 27.6177L89.2874 30.1258C87.9725 28.9182 86.2219 28.3648 84.6423 28.3648C83.0627 28.3648 82.2566 29.0393 82.2566 30.0046C82.2566 31.0911 83.6205 31.4263 85.4932 31.8625C88.1679 32.4643 91.5754 33.264 91.5754 36.8343C91.5754 39.7786 89.4584 42 85.082 42C81.9676 42 79.7285 40.962 78.1978 39.4434L80.0949 36.8384V36.8343Z"
        fill="white"
      />
      <path
        d="M98.7732 25.6104V41.7092H110.254V38.7406H102.225V34.9966H110.083V32.0564H102.225V28.5547H110.254V25.6104H98.7732Z"
        fill="white"
      />
      <path
        d="M117.818 25.6104V41.7092H129.298V38.7406H121.27V34.9966H129.127V32.0564H121.27V28.5547H129.298V25.6104H117.818Z"
        fill="white"
      />
      <path
        d="M62.5324 25.6104L56.2588 41.7092H60.0531L64.6982 28.5789L69.3433 41.7092H73.1132L66.864 25.6104H62.5324Z"
        fill="white"
      />
      <path
        d="M146.348 35.5782C148.099 35.1703 149.898 33.6477 149.898 30.8003C149.898 27.8075 147.83 25.6104 144.451 25.6104H136.863V28.5506H143.942C145.33 28.5506 146.377 29.423 146.377 30.772C146.377 32.121 145.33 32.9934 143.942 32.9934H136.863V41.7052H140.319V35.9377H142.847L146.035 41.7052H150L146.352 35.5742L146.348 35.5782Z"
        fill="white"
      />
      <path
        d="M146.177 6.82565V13.6028C146.177 14.6287 145.697 14.9639 144.622 14.9639H115.436C113.792 14.9639 112.741 14.3904 112.741 13.3646V6.82565H109.089V12.4316C109.089 14.2693 109.061 14.9599 107.563 14.9599H102.319C101.24 14.9599 100.764 14.6247 100.764 13.5988V0H97.1123V14.2693C97.1123 16.4866 98.1911 17.5124 100.911 17.5124H108.458C110.494 17.5124 111.691 17.2015 112.289 16.1998C112.969 17.1974 116.845 17.464 118.799 17.5044C118.848 17.5044 118.897 17.5124 118.95 17.5124H146.051C148.746 17.5124 149.821 16.4866 149.821 14.2693V6.82565H146.169H146.177Z"
        fill="white"
      />
      <path d="M141.976 0.0244255H145.554V3.57458H141.976" fill="white" />
      <path
        d="M149.76 0.0244255H146.181V3.57458H149.76V0.0244255Z"
        fill="white"
      />
      <path d="M106.183 18.506H102.604V22.0562H106.183V18.506Z" fill="white" />
      <path d="M110.388 18.506H106.81V22.0562H110.388V18.506Z" fill="white" />
      <path
        d="M12.3843 22.0117L18.0512 5.90884L22.2241 17.5165H70.9227C72.8972 17.5165 73.9761 17.157 74.5745 16.1554C75.1445 17.0157 76.1907 17.5165 77.7174 17.5165H79.1219C81.0354 17.5165 82.1753 17.157 82.7412 16.1796C83.3111 17.0157 84.3574 17.5165 85.884 17.5165H87.2886C90.1913 17.5165 91.3271 16.6118 91.3271 14.2733V6.8297H87.6753V12.4356C87.6753 14.6085 87.4962 14.9639 85.4322 14.9639C84.0846 14.9639 83.1564 14.4389 83.1564 13.3403V8.85315H79.5046V13.0051C79.5046 14.7458 79.2034 14.9599 77.3184 14.9599C75.7633 14.9599 74.9857 14.4348 74.9857 13.3605V8.84912H71.3339V13.0011C71.3339 14.4793 71.1548 14.9559 69.8072 14.9559H24.6057L19.9891 3.15434H16.0971L9.96196 19.3744H0.0732422V22.0037H12.3761L12.3843 22.0117Z"
        fill="white"
      />
      <path d="M19.8304 13.6392H16.252V17.1893H19.8304V13.6392Z" fill="white" />
      <path d="M19.8304 18.4818H16.252V22.0319H19.8304V18.4818Z" fill="white" />
      <path
        d="M66.3917 38.0096H62.8132V41.5598H66.3917V38.0096Z"
        fill="white"
      />
    </svg>
  );
}
