export const envVars = {
  APP_URL: process.env.NEXT_PUBLIC_BASE_URL || '',
  SONAR_CONNECTION_ID: process.env.SONAR_CONNECTION_ID || '',
  SONAR_PROJECT_KEY: process.env.SONAR_PROJECT_KEY || '',
  NEXT_PUBLIC_CONTENTFUL_SPACE_ID:
    process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID || 'space_id',
  NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN:
    process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN || 'access_token',
  NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT:
    process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT ?? 'environment',
  API: {
    USERS_BASE_URL: process.env.NEXT_PUBLIC_USERS_API_BASE_URL || '',
    VENDORS_BASE_URL: process.env.NEXT_PUBLIC_VENDORS_API_BASE_URL || '',
    COST_CALCULATOR_BASE_URL:
      process.env.NEXT_PUBLIC_COST_CALCULATOR_API_BASE_URL || '',
  },
  NEXT_PUBLIC_MAPS_API_KEY: process.env.NEXT_PUBLIC_MAPS_API_KEY || '',
  FORGEROCK: {
    AM_URL: process.env.NEXT_PUBLIC_FORGEROCK_AM_URL,
  },
  CALENDLY: {
    BASE_URL: process.env.NEXT_PUBLIC_CALENDLY_BASE_URL,
  },
  NEXT_PUBLIC_SOURCE_VERSION: process.env.NEXT_PUBLIC_SOURCE_VERSION,
  NEXT_PUBLIC_REVALIDATION_TOKEN: process.env.NEXT_PUBLIC_REVALIDATION_TOKEN,
  NEXT_PUBLIC_GA_ID: process.env.NEXT_PUBLIC_GA_ID,
  ONLY_UAT_FEATURES_ENABLED:
    process.env.NEXT_PUBLIC_ONLY_UAT_FEATURES_ENABLED === 'true' ? true : false,
  NEXT_UAE_PASS_LOGOUT_URL: process.env.NEXT_UAE_PASS_LOGOUT_URL,
  NEXT_PUBLIC_FORGEROCK_OAUTH_CLIENT:
    process.env.NEXT_PUBLIC_FORGEROCK_OAUTH_CLIENT,
  NEXT_PUBLIC_DATADOG_APP_ID: process.env.NEXT_PUBLIC_DATADOG_APP_ID || '',
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN:
    process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
  NEXT_PUBLIC_DATADOG_SITE: process.env.NEXT_PUBLIC_DATADOG_SITE,
  NEXT_PUBLIC_DATADOG_SERVICE: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
  NEXT_PUBLIC_DATADOG_ENV: process.env.NEXT_PUBLIC_DATADOG_ENV,
  NEXT_PUBLIC_DATADOG_VERSION: process.env.NEXT_PUBLIC_DATADOG_VERSION,
  NEXT_PUBLIC_DATADOG_ALLOWTRACINGURL:
    process.env.NEXT_PUBLIC_DATADOG_ALLOWTRACINGURL || '',
  NEXT_PUBLIC_DATADOG_TRACKUSERINTERACTIONS:
    process.env.NEXT_PUBLIC_DATADOG_TRACKUSERINTERACTIONS === 'true'
      ? true
      : false,
  NEXT_PUBLIC_DATADOG_TRACKRESOURCES:
    process.env.NEXT_PUBLIC_DATADOG_TRACKRESOURCES === 'true' ? true : false,
  NEXT_PUBLIC_DATADOG_TRACKLONGTASKS:
    process.env.NEXT_PUBLIC_DATADOG_TRACKLONGTASKS === 'true' ? true : false,
  NEXT_PUBLIC_DATADOG_FORWARDERRORSTOLOGS:
    process.env.NEXT_PUBLIC_DATADOG_FORWARDERRORSTOLOGS === 'true'
      ? true
      : false,
  NEXT_PUBLIC_UAE_PASS_INITIALIZED:
    process.env.NEXT_PUBLIC_UAE_PASS_INITIALIZED,
  NEXT_PUBLIC_REALM_PATH: process.env.NEXT_PUBLIC_FORGEROCK_REALM_PATH,
  NEXT_PUBLIC_JOURNEY_LOGIN_UAEPASS:
    process.env.NEXT_PUBLIC_FORGEROCK_JOURNEY_LOGIN_UAEPASS,
  NEXT_PUBLIC_SCOPE: process.env.NEXT_PUBLIC_FORGEROCK_SCOPE,
};
